export const AUTH_TOKEN = "/authorization/token/";
export const REVOKE_TOKEN = "/authorization/revoke_token/";
export const USER_DATA = "/authorization/user/";
export const SIGNUP = "/authorization/signup/";
export const USERS_DATA = "/authorization/users/";
export const GET_OTP = "/authorization/logininitiator/";
 

 


export const CONTACT_Us = '/appeglvdKSlu26wb6/tblTc6UUVvC4QyWxB';
export const REQUEST_DOCUMENTS = '/util/request-document';
export const POST_INTERESTED = '/util/iam-interested';
export const AM_I_INTERESTED = '/util/ami-interested';
export const INVITE_USER = '/util/invite-user/';
export const GET_TASKLIST = '/util/all-task/';
export const POST_TASKLIST = '/util/add-task/';
export const ADD_TO_TIMELINE = '/util/add-to-timeline/';
export const ALL_TIMELINE = '/util/all-timeline/';
export const ALL_DOCUMENTS = '/util/all-doc/';
export const INFORMATION_DEVELOPER ='/inv/investor-dashboard/';

//Gen AI 
export const INFORMATION_DEVELOPER_GEN_AI = '/inv/gen-ai-response/';


export const ONBOARD_DEVELOPER = '/api/v1/onboard-company';
export const ONBOARD_DEVELOPER_REFY = '/refy/onboarding-by-refy';
export const ADD_COMPANY_DETAILS = '/developer/add-company-detail';
export const FINANCING_REQUIREMENT = '/developer/financing_requirement';
export const ADD_PROJECT = '/developer/add-project';

export const VALIDATE_USER = '/developer/validate-user';

export const FETCH_ALL_PROJECT =  '/refy/fetch-all-project';
export const UPDATE_ABOUTUS = 'refy/update-about-company';
export const UPDATE_TAGLINE = 'refy/update-tagline-company';
export const UPDATE_DOCUMENT = '/refy/update-document';
export const DELETE_DOCUMENT = '/refy/delete-document/';
export const DELETE_PROJECT = '/refy/delete-project/';