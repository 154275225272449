import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, IconButton } from "@mui/material";
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import deleteIcon from "../../../../assets/deleteIcon.svg"
import * as projectActions from "../../../../store/project";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('ALP Technologies', 'Feb 24th, 2024, 08.53 AM', 'Development', 'energy', 'Term-sheet created', 'action'),

];

export default function AllProjects() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state?.project?.projects);
  const user = useSelector((state) => state?.auth?.user)
  const [openDialog, setOpenDialog] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const navigateToProject = (uuid) => {
    navigate('/project/' + uuid)
  }


  const handleDeleteClick = (e, projectUuid) => {
    if (user?.email?.includes('refycap.com')) {
      e.stopPropagation();
      setProjectToDelete(projectUuid);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setProjectToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (projectToDelete) {
      dispatch(projectActions.deleteProject(projectToDelete));
      setProjectToDelete(null)
    }
    handleCloseDialog();
  };

  useEffect(() => {

    async function fetchData() {

      const raw = ""
      const company = await dispatch(projectActions.fetchAllProject(raw));

    }

    fetchData();

  }, []);
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">

          <TableHead>
            <TableRow sx={{ backgroundColor: "#F3F4F9" }}>
              <TableCell>Project UUID</TableCell>
              <TableCell align="center">Secret Key</TableCell>
              <TableCell align="center">Project Name</TableCell>
              <TableCell align="right">Created by</TableCell>
              <TableCell align="right">Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {projects?.map((row) => {
              let projectName = ""
              if (typeof (row?.projectData) === 'string') {
                const projectdata = JSON.parse(row?.projectData)
                projectName = projectdata?.data?.BasicData?.ProjectName;

              }
              return (
                <TableRow key={row?.id} onClick={() => navigateToProject(row?.uuid)} sx={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {row?.uuid}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.secretKey ? row?.secretKey : 'N.A'}
                  </TableCell>
                  <TableCell align="center">{projectName}</TableCell>
                  <TableCell align="right">{row?.developerEmail}</TableCell>
                  <TableCell align="right">

                    <IconButton aria-label="deleteIcon" onClick={(e) => handleDeleteClick(e, row?.uuid)}>
                      <img src={deleteIcon} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this project?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone. All data associated with this project will be permanently deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}