import React, { ReactElement, useState, useEffect } from "react";
import {
    Box,
    Avatar,
    Typography,
    Button,
    Backdrop,
    IconButton,
    TextField,
    MenuItem,
    Input,
    CircularProgress
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import createproject from "../../../../assets/createproject.svg";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import * as projectActions from "../../../../store/project";
import Close from "../../../../assets/close.svg";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import { useNavigate, useLocation } from "react-router-dom";
import UploadDocuments from "./UploadDocuments";
import closed from "../../../../assets/close.svg";
import axios from "axios";
import { client } from "../../../../services/client";
import config from "../../../../config/config";
import { optionsDocumentsType } from "../../../../utils/category";



function UploadPopup(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [link, setLink] = React.useState("");
    const [documentMulti, setDocumentMulti] = React.useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const project = useSelector((state) => state.project);
    const token = useSelector((state) => state?.auth?.token);
    const projectdata = useSelector((state) => state?.project?.projectBasicData);
    const navigate = useNavigate();
    let { uuid } = useParams();
    const [name, setName] = useState("");
    const [phoneno, setPhoneno] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [errorDocument, setErrorDocument] = useState(false);
    const [description, setDescription] = useState("");
    const [emailId, setEmail] = useState("");
    const user = useSelector((state) => state?.auth?.user);
    const logedIn = useSelector((state) => state?.auth?.loggedIn);
    const getStarted = useSelector((state) => state?.auth?.GetStarted);
    const [contactmethod, setContactmethod] = useState({
        email: false,
        phone: false,
    });

    useEffect(() => {
        // setDetails(props?.details);
        if (project?.link) {
            const encodedUrl = encodeURIComponent(project?.link);
            // Create the Google Docs viewer URL
            const googleDocViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
            setLink(googleDocViewerUrl)
        }
    }, [project]);

    const handleClose = async () => {
        setIsLoading(false);
        const authdata = await dispatch(projectActions.closeUploadDocBackdrop());
        resetAll()

    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleChange = (event) => {
        setContactmethod({
            ...contactmethod,
            [event.target.name]: event.target.checked,
        });
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };
    const handlePhoneNoChange = (e) => {
        setPhoneno(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const closeRedirect = () => {
        if (logedIn) {
            setShowPopup(false);
            navigate("/dashboard")
        }
        else {
            setShowPopup(false);
            navigate("/")
        }

    }
    const resetAll = async () => {
        const authdata = await dispatch(projectActions.closeUploadDocBackdrop());
        const docu = await dispatch(projectActions.allDocuments(uuid));
        setDocumentMulti([]);
        setSelectedFile(null);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        // formData.append('files', event.target.files);
        formData.append('projectName', 'N.A');
        formData.append('email', 'badal@refycap.com');
        formData.append('category', documentMulti[0]);
        formData.append('projectUuid', uuid);
        formData.append('text', 'Refy has upload documents');

        selectedFile.forEach(file => {
            return formData.append('files', file)
        })

        axios.post('https://refy-admin-dashboard-backend-vqv7qc6r6q-as.a.run.app/api/v1/upload/project', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token?.idToken,
            },
        })
            .then(response => {
                console.log(response);
                // if (response?.data?.msg?.files) {
                //   setUrl(response?.data?.msg?.files)
                resetAll()
                setIsLoading(false);
                // }
            })
            .catch(error => {
                resetAll()
                console.error(error);
                setIsLoading(false);
            });

    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    }


    const handleDocumentChange = (event) => {
        // setIndustry(event.target.value);
        setErrorDocument(false);
        const {
            target: { value },
        } = event;
        setDocumentMulti(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };


    return (
        <>
            <Box>
                <CssBaseline />
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        display: "flex",
                        justifyContent: "center",
                    }}
                    open={project?.uploadBackdrop}
                >
                    <Box
                        sx={{
                            backgroundColor: "#fff",
                            height: "auto",
                            maxWidth: "500px",
                            width: "500px",
                            margin: "32px",
                            padding: "24px 24px",
                            overflowY: "auto",
                            borderRadius: "8px"
                        }}
                    >

                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant="h6" sx={{ color: "#12190F", fontWeight: "700", letterSpacing: "0.9px" }}>
                                    Upload a document
                                </Typography>
                                <IconButton aria-label="delete" size="small" onClick={handleClose}>
                                    <Box>
                                        <img src={Close} height={"24px"} />
                                    </Box>
                                </IconButton>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                height: "calc ( 100vh - 120px)",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CssBaseline />
                            <Box
                                sx={{
                                    display: "flex",
                                    width: { xs: "100%", sm: "500px" },
                                    p: 2,
                                    flexDirection: "column",
                                }}
                            >



                                <UploadDocuments setSelectedFile={setSelectedFile} selectedFile={selectedFile} />

                                <Box sx={{ pt: 3 }}>
                                    <label for="industry">
                                        Document Type<span style={{ color: "red" }}>*</span>
                                    </label>

                                    <TextField
                                        id="outlined-select-gender"
                                        select
                                        label={
                                            !documentMulti || documentMulti?.length == 0
                                                ? "Select documents type"
                                                : ""
                                        }
                                        InputLabelProps={{ shrink: false }}
                                        SelectProps={{
                                            multiple: false,
                                            value: documentMulti,
                                            onChange: handleDocumentChange,
                                            renderValue: (selected) => selected.join(", "),
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                        size="medium"
                                        value={documentMulti}
                                        onChange={handleDocumentChange}
                                        sx={{
                                            width: "100%",
                                            color: "#606060",
                                            marginTop: "8px",


                                            "& .MuiInputLabel-root": {
                                                color: "#9BA5B7 !important",
                                            },
                                        }}
                                    >
                                        {optionsDocumentsType.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                <Checkbox
                                                    checked={documentMulti.indexOf(option.value) > -1}
                                                />
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    {errorDocument && (
                                        <span style={{ color: "#F55B64", fontSize: "14px" }}>
                                            Select an option
                                        </span>
                                    )}
                                </Box>
                                {isLoading ? (
                                    <Button
                                        disabled
                                        fullWidth
                                        variant="contained"
                                        sx={{
                                            mt: 0,
                                            mb: 2,
                                            height: "52px",
                                            textTransform: "none",
                                            fontSize: "18px",
                                        }}
                                    >
                                        <CircularProgress size={24} color="inherit" />
                                    </Button>

                                ) : (
                                    <Button
                                        onClick={handleSubmit}
                                        fullWidth
                                        variant="contained"
                                        sx={{
                                            mt: 0,
                                            mb: 2,
                                            height: "52px",
                                            textTransform: "none",
                                            fontSize: "18px",
                                        }}
                                    >
                                        Upload
                                    </Button>
                                )}

                            </Box>
                        </Box>

                    </Box>
                </Backdrop>
            </Box>
        </>
    );
}

export default UploadPopup;
