export const projectData = {
    "companyProfile": {
      "name": "LIQUINEX",
      "tagline": "Innovative Water Treatment and Recycling Solutions",
      "about": "Liquinex, a Singapore-based Small and Medium Enterprise (SME) Start-Up, is at the forefront of addressing global water treatment and recycling challenges with cutting-edge ceramic membrane technology. Leveraging its unique Water-As-A-Service business model and a strong track record, Liquinex offers compact, efficient, and sustainable water treatment solutions. This business plan outlines Liquinex's mission, technology, market potential, strategic goals, recent achievements, and a promising pipeline of projects about to be awarded.",
      "logo": "",
      "owner": {
        "name": "Bashir Ahmad",
        "designation": "CEO, Technical Director",
        "profileImage": ""
      }
    },
    "projectStage": "Development",
    "capitalRequirement": "$30000",
    "files": [
      {
        "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021.pdf?alt=media&token=3b5a038e-a2d9-400d-b8f8-b075321b55f0",
        "title": "Detailed business plan outlining our operations, market, and growth strategy",
        "type": "pdf",
        "thumbnail": ""
      },
      {
        "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FNon%20HF%20Recycle%20%20Project%20update%2007%20Apr%20%2024.pptx?alt=media&token=ac72d5a9-1828-4db7-a45b-623334261139",
        "title": "Financial projections for the next 3-5 years",
        "type": "ppt",
        "thumbnail": ""
      },
      {
        "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FREC%20Project%20FInancials_09052024%20(1).xlsx?alt=media&token=56b042b1-4ea1-4374-a7e0-b8f0e065b1d2",
        "title": "Financial projections for the next 3-5 years",
        "type": "xlx",
        "thumbnail": ""
      },
      {
        "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20-%20Company%20%26%20Business%20Plan%20Oct2023.pdf?alt=media&token=029d8a99-c1d7-4a01-a48a-10e718521b74",
        "title": "Detailed business plan outlining our operations, market, and growth strategy",
        "type": "pdf",
        "thumbnail": ""
      }
    ],
    "documentations": [
      {
        "titile": "Incorporation",
        "files": [
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021.pdf?alt=media&token=3b5a038e-a2d9-400d-b8f8-b075321b55f0",
            "title": "Detailed business plan outlining our operations, market, and growth strategy",
            "type": "pdf",
            "thumbnail":"https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0001.jpg?alt=media&token=53227040-1ee9-4492-b98b-16473289ce3a"
          },
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FNon%20HF%20Recycle%20%20Project%20update%2007%20Apr%20%2024.pptx?alt=media&token=ac72d5a9-1828-4db7-a45b-623334261139",
            "title": "Financial projections for the next 3-5 years",
            "type": "ppt",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"
          },
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FREC%20Project%20FInancials_09052024%20(1).xlsx?alt=media&token=56b042b1-4ea1-4374-a7e0-b8f0e065b1d2",
            "title": "Financial projections for the next 3-5 years",
            "type": "xlx",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"
          },
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20-%20Company%20%26%20Business%20Plan%20Oct2023.pdf?alt=media&token=029d8a99-c1d7-4a01-a48a-10e718521b74",
            "title": "Detailed business plan outlining our operations, market, and growth strategy",
            "type": "pdf",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"   
          }
        ]
      },
      {
        "titile": "Project related files",
        "files": [
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021.pdf?alt=media&token=3b5a038e-a2d9-400d-b8f8-b075321b55f0",
            "title": "Detailed business plan outlining our operations, market, and growth strategy",
            "type": "pdf",
            "thumbnail":"https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0001.jpg?alt=media&token=53227040-1ee9-4492-b98b-16473289ce3a"
          },
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FNon%20HF%20Recycle%20%20Project%20update%2007%20Apr%20%2024.pptx?alt=media&token=ac72d5a9-1828-4db7-a45b-623334261139",
            "title": "Financial projections for the next 3-5 years",
            "type": "ppt",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"
          },
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FREC%20Project%20FInancials_09052024%20(1).xlsx?alt=media&token=56b042b1-4ea1-4374-a7e0-b8f0e065b1d2",
            "title": "Financial projections for the next 3-5 years",
            "type": "xlx",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"
          },
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20-%20Company%20%26%20Business%20Plan%20Oct2023.pdf?alt=media&token=029d8a99-c1d7-4a01-a48a-10e718521b74",
            "title": "Detailed business plan outlining our operations, market, and growth strategy",
            "type": "pdf",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"   
          }
        ]
      },
      {
        "titile": "Financial documents",
        "files": [
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021.pdf?alt=media&token=3b5a038e-a2d9-400d-b8f8-b075321b55f0",
            "title": "Detailed business plan outlining our operations, market, and growth strategy",
            "type": "pdf",
            "thumbnail":"https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0001.jpg?alt=media&token=53227040-1ee9-4492-b98b-16473289ce3a"
          },
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FNon%20HF%20Recycle%20%20Project%20update%2007%20Apr%20%2024.pptx?alt=media&token=ac72d5a9-1828-4db7-a45b-623334261139",
            "title": "Financial projections for the next 3-5 years",
            "type": "ppt",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"
          } 
 
        ]
      },
      {
        "titile": "Permits and License",
        "files": [
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FREC%20Project%20FInancials_09052024%20(1).xlsx?alt=media&token=56b042b1-4ea1-4374-a7e0-b8f0e065b1d2",
            "title": "Financial projections for the next 3-5 years",
            "type": "xlx",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"
          },
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20-%20Company%20%26%20Business%20Plan%20Oct2023.pdf?alt=media&token=029d8a99-c1d7-4a01-a48a-10e718521b74",
            "title": "Detailed business plan outlining our operations, market, and growth strategy",
            "type": "pdf",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"   
          }
        ]
      }
      ,
      {
        "titile": "Bio and Organisation chart",
        "files": [
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FREC%20Project%20FInancials_09052024%20(1).xlsx?alt=media&token=56b042b1-4ea1-4374-a7e0-b8f0e065b1d2",
            "title": "Financial projections for the next 3-5 years",
            "type": "xlx",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"
          },
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20-%20Company%20%26%20Business%20Plan%20Oct2023.pdf?alt=media&token=029d8a99-c1d7-4a01-a48a-10e718521b74",
            "title": "Detailed business plan outlining our operations, market, and growth strategy",
            "type": "pdf",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"   
          },
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20-%20Company%20%26%20Business%20Plan%20Oct2023.pdf?alt=media&token=029d8a99-c1d7-4a01-a48a-10e718521b74",
            "title": "Detailed business plan outlining our operations, market, and growth strategy",
            "type": "pdf",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"   
          }
        ]
      },
      {
        "titile": "Collateral (optinal)",
        "files": [
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FREC%20Project%20FInancials_09052024%20(1).xlsx?alt=media&token=56b042b1-4ea1-4374-a7e0-b8f0e065b1d2",
            "title": "Financial projections for the next 3-5 years",
            "type": "xlx",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"
          },
          {
            "link": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20-%20Company%20%26%20Business%20Plan%20Oct2023.pdf?alt=media&token=029d8a99-c1d7-4a01-a48a-10e718521b74",
            "title": "Detailed business plan outlining our operations, market, and growth strategy",
            "type": "pdf",
            "thumbnail": "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"   
          }
        ]
      }

    ],
    "timeline":[
      {
        user:{
          displayName: "Refy",
          photoURL: "logo"
        },
        text:"Refy has uploaded a investment documents for Liquinex",
        subtext:"",
        files: [  {
          link: "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20-%20Company%20%26%20Business%20Plan%20Oct2023.pdf?alt=media&token=029d8a99-c1d7-4a01-a48a-10e718521b74",
          title: "Detailed business plan outlining our operations, market, and growth strategy",
          type: "pdf",
          thumbnail:"https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0030.jpg?alt=media&token=debab239-4e7b-4d78-8a80-eab9190023d0"
        }],
        timestamp:"June 23, 2024"
      },
      {
        user:{
          displayName: "Refy",
          photoURL: "logo"
        },
        text:"Refy has uploaded a investment documents for Liquinex",
        subtext:"",
        files:[
          {
            link: "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021.pdf?alt=media&token=3b5a038e-a2d9-400d-b8f8-b075321b55f0",
            title: "Detailed business plan outlining our operations, market, and growth strategy",
            type: "pdf",
            thumbnail:"https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0001.jpg?alt=media&token=53227040-1ee9-4492-b98b-16473289ce3a"
          },
          {
            link: "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FNon%20HF%20Recycle%20%20Project%20update%2007%20Apr%20%2024.pptx?alt=media&token=ac72d5a9-1828-4db7-a45b-623334261139",
            title: "Financial projections for the next 3-5 years",
            type: "ppt",
            thumbnail:"https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0009.jpg?alt=media&token=2f26183a-33f2-45f3-baa2-b63a53e1a28b"
          },
          {
            link: "https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FREC%20Project%20FInancials_09052024%20(1).xlsx?alt=media&token=56b042b1-4ea1-4374-a7e0-b8f0e065b1d2",
            title: "Financial projections for the next 3-5 years",
            type: "xlx",
            thumbnail:"https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/project%2Fbadal-refycap-com%2Ftest-project%2FLiquinex%20Corporate%20Presentation%20-%202021_page-0030.jpg?alt=media&token=debab239-4e7b-4d78-8a80-eab9190023d0"
          } ],
        timestamp:"June 23, 2024"
      }
    ]
  }