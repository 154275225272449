import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../additionalDetails/components/Header";
import { useSelector, useDispatch } from "react-redux";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Typography, Box, Button, Avatar, CircularProgress, TextField, IconButton, Tooltip } from "@mui/material";
import underDevelopment from "../../assets/underDevelopment.svg";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import AddIcon from '@mui/icons-material/Add';
import PropTypes from "prop-types";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import * as authActions from "../../store/auth";
import * as projectActions from "../../store/project";
import logoutImg from "../../assets/logout.svg";
import editIcon from "../../assets/edit_icon.svg";
import copyIcon from "../../assets/copy.svg";
import Documents from "./components/Documents/Documents";
import AboutusPopup from "./components/AboutusPopup/AboutusPopup";
import TaglineUpdatePopup from "./components/TaglineUpdatePopup/TaglineUpdatePopup";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';





function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function getFocusAreas(data) {
  const focusAreaKeys = [
    'RefinancingAndRevolvinBridgeLoans',
    'RenewableEnergy',
    'EnergyEfficiency',
    'SustainableTransportation',
    'WasteManagement',
    'WaterConservation',
    'BiomassBiofuel',
    'Battery'
  ];

  return focusAreaKeys
    .filter(key => data[key] === true)
    .map(key => {
      // Convert camelCase to spaces and capitalize first letter of each word
      return key
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim();
    });
}

function ProjectsDetails(props) {
  const { uuid } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = React.useState(0);
  const [parsedData, setParsedData] = useState(null);
  const [summary, setSummary] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth);
  const project = useSelector((state) => state?.project);
  const projectdata = useSelector((state) => state?.project?.projectBasicData);
  const projectGenAIBasicData = useSelector((state) => state?.project?.projectGenAIBasicData);
  const secretKey = useSelector((state) => state?.project?.secretKey);
  const token = useSelector((state) => state?.auth?.token);
  const aboutusPopup = useSelector((state) => state?.project?.aboutusPopup);
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [missingInfo, setMissingInfo] = useState([]);
 
 
  const [verificationStatus, setVerificationStatus] = useState({
    company: null,
    owner: null
  });
 

  useEffect(() => {
    if (uuid) {
      dispatch(projectActions.informationsDeveloper(uuid));
      dispatch(projectActions.informationsDeveloperGenAi(uuid));
    }
  }, [uuid, dispatch]);

  useEffect(() => {
    if (projectGenAIBasicData) {
      setVerificationStatus({
        company: projectGenAIBasicData.companyVerified,
        owner: projectGenAIBasicData.ownerVerified
      });

      const missingFields = Object.entries(projectGenAIBasicData)
        .filter(([key, value]) => (value === '' || value === null) && key !== 'companyVerified' && key !== 'ownerVerified')
        .map(([key]) => key);

      if (missingFields.length > 0) {
        setMissingInfo(missingFields);
        setShowInfoPopup(true);
      }
    }
  }, [projectGenAIBasicData]);

  useEffect(() => {
    if (verificationStatus.company === false) {
      setVerificationMessage("Unable to verify Company Name from available resources");
      setShowVerificationPopup(true);
    } else if (verificationStatus.company === true && verificationStatus.owner === false) {
      setVerificationMessage("Unable to verify Owner Name from available resources");
      setShowVerificationPopup(true);
    }
  }, [verificationStatus]);

  const handleCloseVerificationPopup = () => {
    setShowVerificationPopup(false);
    if (verificationStatus.company === false) {
      setVerificationStatus(prev => ({ ...prev, company: true }));
    } else if (verificationStatus.owner === false) {
      setVerificationStatus(prev => ({ ...prev, owner: true }));
    }
  };

  const handleCloseInfoPopup = () => {
    setShowInfoPopup(false);
  };


  const logout = async (e) => {
    e.preventDefault();
    const company = await dispatch(authActions.logoutSuccess());
    navigate("/login");
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    async function fetchProjectData() {
      if (!uuid) return;
      if (user) {
        let uuidData = {
          "uuid": uuid
        }
        const projectdata = await dispatch(projectActions.informationsDeveloper(uuid));
        // if (!project?.unauthorised && typeof(project?.projectBasicData) === 'string') {
        //   const parsed = JSON.parse(project?.projectBasicData);
        //   setParsedData(parsed);
        // }
      }
    }

    fetchProjectData();
  }, [uuid]);


  useEffect(() => {
    async function fetchProjectData() {
      if (!uuid) return;
      if (user) {
        let uuidData = {
          "uuid": uuid
        }
        const projectdata = await dispatch(projectActions.informationsDeveloperGenAi(uuid));
      }
    }
    fetchProjectData();
  }, [uuid]);

  useEffect(() => {
    if (projectGenAIBasicData) {
      if (projectGenAIBasicData.companyVerified === false) {
        setVerificationMessage("Unable to verify Company Name from available resources");
        setShowVerificationPopup(true);
      } else if (projectGenAIBasicData.ownerVerified === false) {
        setVerificationMessage("Unable to verify Owner Name from available resources");
        setShowVerificationPopup(true);
      }

      const missingFields = Object.entries(projectGenAIBasicData)
        .filter(([key, value]) => (value === '' || value === null) && key !== 'companyVerified' && key !== 'ownerVerified')
        .map(([key]) => key);

      if (missingFields.length > 0) {
        setMissingInfo(missingFields);
        setShowInfoPopup(true);
      }
    }
  }, [projectGenAIBasicData]);
  useEffect(() => {
    async function fetchDocumentsData() {
      if (!uuid) return;
      if (user) {
        const docu = await dispatch(projectActions.allDocuments(uuid));
      }
    }
    fetchDocumentsData();
  }, [uuid, user]);

  useEffect(() => {
    async function fetchTimelineData() {
      if (!uuid) return;
      if (user && user.token) {

      }
    }
    fetchTimelineData();
  }, [uuid, user.token]);

  const handleSummaryChange = (e) => {
    setSummary(e.target.value)
  }

  const investorView = () => {
    window.open('https://www.investor.refycap.com/project/' + uuid, '_blank');
  }

  // const handleCloseVerificationPopup = () => {
  //   setShowVerificationPopup(false);
  // };

  // const handleCloseInfoPopup = () => {
  //   setShowInfoPopup(false);
  // };
  const aboutusPopupFun = async () => {
    const openUpload = await dispatch(projectActions?.openAboutUsBackdrop());
  }

  const handleCopySecretKey = () => {
    navigator.clipboard.writeText(secretKey).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    });
  };


  const taglineFun = async () => {
    const openUpload = await dispatch(projectActions?.openTaglineBackdrop());
  }
  // useEffect(() => {

  //     try {
  //       const  parsed  = JSON.parse(project?.projectBasicData);
  //       setParsedData(parsed);
  //       setLoading(false);
  //     } catch (e) {
  //       setError("Error parsing project data");
  //       setLoading(false);
  //     }

  // }, [project, error]);

  return (
    <>

      <Header />
      <AboutusPopup />
      <TaglineUpdatePopup />

      <Box sx={{
        maxwidth: "1400px", width: "calc(100% - 96px)", padding: "24px", margin: "24px",
        display: "flex", justifyContent: "center", alignItems: "center",
        flexDirection: "column", backgroundColor: "#fff", gap: 2, borderRadius: "8px", border: "1px solid #E5E5E5"
      }}>
        <Box sx={{ display: 'flex', justifyContent: "space-between", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "16px 8px",
            }}
          >
            <Box p={1} pb={1}>
              <Box sx={{ width: "66px", height: "66px", borderRadius: "8px", backgroundColor: "#F3F4F9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={projectdata?.organisation?.logo} width="80%" />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                pl: 1,
                pt: 0.6,
                flexDirection: "column",
                justifyContent: "center",
                gap: 0.5
              }}
            >
              <Typography sx={{ color: "#12190F", fontSize: "18px", lineHeight: "27px", fontWeight: "700" }}>{projectdata?.organisation?.name} </Typography>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                {projectdata?.organisation?.tagline}
              </Typography>

            </Box>

          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
              <Typography sx={{ color: "#12190F", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                ID: {secretKey}
              </Typography>
              <Tooltip title={copySuccess ? "Copied!" : "Copy to clipboard"}>
                <IconButton onClick={handleCopySecretKey}>
                  <img src={copyIcon} height={20} />
                </IconButton>
              </Tooltip>
            </Box>
            <Button sx={{ height: "38px" }} startIcon={<img src={editIcon} />} onClick={taglineFun}>
              Edit Tagline
            </Button>
            <Button sx={{ height: "38px" }} startIcon={<img src={editIcon} />} >
              Edit
            </Button>
            <Button sx={{ height: "38px" }} startIcon={<RemoveRedEyeOutlinedIcon />} onClick={investorView}>
              Investor view
            </Button>
          </Box>

        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "20%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Company name
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "14px", lineHeight: "16px", fontWeight: "700" }}>{projectGenAIBasicData?.companyName ? projectGenAIBasicData?.companyName : projectdata?.onboarding?.data?.CompanyName}</Typography>
            </Box>

          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "20%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Year Founded
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "18px", lineHeight: "27px", fontWeight: "700" }}>{projectdata?.onboarding?.data?.YearFound}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "20%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Industry
              </Typography>
            </Box>

            <Box>
              {projectdata?.onboarding?.data && (
                <Typography sx={{ color: "#12190F", fontSize: "12px", lineHeight: "27px", fontWeight: "700" }}>
                  {getFocusAreas(projectdata.onboarding.data).join(', ') || 'None specified'}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "20%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                No of Employees
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "18px", lineHeight: "27px", fontWeight: "700" }}>{projectdata?.onboarding?.data?.NumberOfEmployee}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "20%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Annual revenue
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "18px", lineHeight: "27px", fontWeight: "700" }}>{projectdata?.onboarding?.data?.AnnualRevenue}</Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "30%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Website
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "18px", lineHeight: "27px", fontWeight: "700" }}>{projectdata?.onboarding?.data?.Website}</Typography>
            </Box>

          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "30%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Owner's Name
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "14px", lineHeight: "16px", fontWeight: "700" }}>{projectGenAIBasicData?.ownerName}</Typography>
            </Box>

          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "30%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Owner's Designation
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "14px", lineHeight: "16px", fontWeight: "700" }}>{projectGenAIBasicData?.ownerDesignation}</Typography>
            </Box>

          </Box>
        </Box>


        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "20%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Project name
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "18px", lineHeight: "27px", fontWeight: "700" }}>
                {projectdata?.projectData?.data?.BasicData?.ProjectName}
              </Typography>
            </Box>

          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "20%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Project location
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "18px", lineHeight: "27px", fontWeight: "700" }}> {projectdata?.projectData?.data?.BasicData?.ProjectLocation}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "20%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Project stage
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "18px", lineHeight: "27px", fontWeight: "700" }}>{projectdata?.projectData?.data?.BasicData?.ProjectStage}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "20%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Financing amount sought
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "14px", lineHeight: "16px", fontWeight: "700" }}>{projectGenAIBasicData?.capitalRequired ? projectGenAIBasicData?.capitalRequired : projectdata?.projectData?.data?.ProjectQuestion?.q1}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "flex-start", width: "20%" }}>
            <Box>
              <Typography sx={{ color: "#ABABAB", fontSize: "14px", lineHeight: "16px", fontWeight: "400" }}>
                Expected payback period
              </Typography>
            </Box>

            <Box>
              <Typography sx={{ color: "#12190F", fontSize: "18px", lineHeight: "27px", fontWeight: "700" }}>{projectdata?.projectData?.data?.ProjectQuestion?.payback}</Typography>
            </Box>
          </Box>
        </Box>

      </Box>


      <Box sx={{
        maxwidth: "1400px", width: "calc(100% - 96px)", padding: "24px", margin: "24px",
        display: "flex", justifyContent: "center", alignItems: "center",
        flexDirection: "column", backgroundColor: "#fff", gap: 2, borderRadius: "8px"
      }}>
        <Box sx={{ display: "flex", flexDirection: "row", width: '100%', justifyContent: "space-between" }}>
          <Typography>
            About Company
          </Typography>
          <Button sx={{ height: "38px" }} startIcon={<img src={editIcon} />} onClick={aboutusPopupFun}>
            Edit
          </Button>

        </Box>


        <Box sx={{
          display: "flex", width: '100%', flexDirection: "column", gap: 1, pt: 1,
          height: "150px",
          padding: "12px 20px",
          boxSizing: "border-box",
          border: "2px solid #E5E5E5",
          borderRadius: "4px",
          backgroundColor: "#fff",
          fontSize: "16px",
          overflowY: 'auto',
          resize: "none"
        }}>
          <p>{projectdata?.organisation?.aboutUs}</p>


        </Box>
      </Box>

      <Box sx={{
        maxwidth: "1400px", width: "calc(100% - 96px)", padding: "24px", margin: "24px",
        display: "flex", justifyContent: "center", alignItems: "center",
        flexDirection: "column", backgroundColor: "#fff", gap: 2, borderRadius: "8px"
      }}>
        <Box sx={{ display: "flex", flexDirection: "row", width: '100%', justifyContent: "space-between" }}>
          <Typography>
            Summary
          </Typography>
          <Button sx={{ height: "38px" }} startIcon={<img src={editIcon} />}  >
            Edit
          </Button>

        </Box>


        <Box sx={{
          display: "flex", width: '100%', flexDirection: "column", gap: 1, pt: 1,
          height: "150px",
          padding: "12px 20px",
          boxSizing: "border-box",
          border: "2px solid #E5E5E5",
          borderRadius: "4px",
          backgroundColor: "#fff",
          fontSize: "16px",
          overflowY: 'auto',
          resize: "none"
        }}>
          <p>{projectGenAIBasicData?.summary}</p>


        </Box>
      </Box>
      <Box sx={{
        maxwidth: "1400px", width: "calc(100% - 96px)", padding: "24px", margin: "24px",
        display: "flex", justifyContent: "center", alignItems: "center",
        flexDirection: "column", backgroundColor: "#fff", gap: 2, borderRadius: "8px"
      }}>
        <Documents />
      </Box>

      <Dialog
        open={showVerificationPopup}
        onClose={handleCloseVerificationPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {verificationMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVerificationPopup} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showInfoPopup}
        onClose={handleCloseInfoPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Need More Information about:"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ul>
              {missingInfo.map((field, index) => (
                <li key={index}>{field}</li>
              ))}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfoPopup} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
export default ProjectsDetails;

