import {
  BACKDROP_OPEN_FULFILLED,
  BACKDROP_CLOSE_FULFILLED,
  FILE_UPLOAD_FULFILLED,
  SAVE_PROJECT_BASIC_DATA,
  SAVE_PROJECT_SUMMARY_DATA,
  SAVE_PROJECT_DATA,
  SAVE_PROJECT_GO_DASHBOARD,
  CONTACT_US_SUCCESS,
  BACK_PROJECT_BASIC,
  BACK_PROJECT_SUMMARY,
  BACK_PROJECT_QUESTION,
  BACK_PROJECT_FINAL,
  PROJECT_DATA_SAVE_DRAFT,
  INITIAL_PROJECT_DATA_SAVE,
  FETCH_PROJECT_START,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  POST_INTERESTED_PENDING,
  POST_INTERESTED_SUCCESS,
  POST_INTERESTED_FAILURE,
  GET_TASK_LIST_SUCCESS,
  POST_TASK_LIST_SUCCESS,
  AMI_INTERESTED_SUCCESS,
  AMI_INTERESTED_FAILURE,
  GET_TIMELINE_LIST_PENDING,
  GET_TIMELINE_LIST_SUCCESS,
  GET_TIMELINE_LIST_FAILURE,
  POST_TIMELINE_LIST_PENDING,
  POST_TIMELINE_LIST_SUCCESS,
  POST_TIMELINE_LIST_FAILURE,
  ALL_DOCUMENTS_PENDING,
  ALL_DOCUMENTS_SUCCESS,
  ALL_DOCUMENTS_FAILURE,
  INFORMATION_DEVELOPER_SUCCESS,
  INFORMATION_DEVELOPER_FAILURE,
  INFORMATION_DEVELOPER_PENDING,
  INFORMATION_DEVELOPER_UNAUTHORISED,
  HIGHLIGHT_I_AM_INTERESTED,
  HIGHLIGHT_REMOVE_I_AM_INTERESTED,
  HIGHLIGHT_MORE_QUESTION,
  HIGHLIGHT_REMOVE_MORE_QUESTION,
  HIGHLIGHT_DOCUMENTS,
  HIGHLIGHT_REMOVE_DOCUMENTS,
  TASK_ANIMATION_START,
  TASK_ANIMATION_END,
  FETCH_ALL_PROJECT_PENDING,
  FETCH_ALL_PROJECT_SUCCESS,
  FETCH_ALL_PROJECT_FAILURE,
  INFORMATION_DEVELOPER_GEN_AI_PENDING,
  INFORMATION_DEVELOPER_GEN_AI_SUCCESS,
  INFORMATION_DEVELOPER_GEN_AI_FAILURE,
  iamInterested,
} from "./actions";

import { projectData } from "../../utils/mockdata";

const initialState = {
  snackbar: false,
  openBackdrop: false,
  url: "",
  basicData: true,
  summaryData: false,
  fileSentTollm: false,
  summaryFromLlm: false,
  additionalQuestion: false,
  contactUs: false,
  finalSteps: false,
  contactUsBackdrop: false,
  requestDocumentsBackdrop: false,
  inviteOthersBackdrop: false,
  announcementBackdrop: false,
  projectData: null,
  loading: false,
  error: null,
  onboardingComplete: false,
  iamInterested: false,
  unauthorised: false,
  highlightIam: false,
  highlightMoreQ: false,
  highlightdoc: false,
  animationtask: false,
  uploadBackdrop: false,
  aboutusPopup: false,
  taglinePopup: false,
  deleteBackdrop: false,
  editBackdrop: false,
  doc: null,
  projectBasicData: [],
  projectGenAIBasicData: {},
  project: [],
  taskList: [],
  ...projectData
};
export const projectreducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_FULFILLED": {
      return { ...state, ...action.payload };
    }
    case BACKDROP_OPEN_FULFILLED: {
      return { ...state, ...action.payload };
    }
    case BACKDROP_CLOSE_FULFILLED: {
      return { ...state, ...action.payload };
    }
    case FILE_UPLOAD_FULFILLED: {
      return { ...state, ...action.payload };
    }
    case SAVE_PROJECT_BASIC_DATA: {
      return { ...state, ...action.payload };
    }
    case SAVE_PROJECT_SUMMARY_DATA: {
      return { ...state, ...action.payload }
    }
    case SAVE_PROJECT_DATA: {
      return { ...state, ...action.payload }
    }
    case SAVE_PROJECT_GO_DASHBOARD: {
      return { ...state, ...action.payload }
    }
    case CONTACT_US_SUCCESS: {
      return { ...state, ...action.payload }
    }
    case BACK_PROJECT_BASIC: {
      return { ...state, ...action.payload }
    }
    case BACK_PROJECT_SUMMARY: {
      return { ...state, ...action.payload }
    }
    case BACK_PROJECT_QUESTION: {
      return { ...state, ...action.payload }
    }
    case BACK_PROJECT_FINAL: {
      return { ...state, ...action.payload }
    }
    case PROJECT_DATA_SAVE_DRAFT: {
      return { ...state, ...action.payload }
    }
    case INITIAL_PROJECT_DATA_SAVE: {
      return { ...state, ...action.payload }
    }
    case FETCH_PROJECT_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        onboardingComplete: action.payload.onboardingComplete,
        error: null
      };
    case FETCH_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case POST_INTERESTED_SUCCESS:
      return {
        ...state,
        loading: false,
        iamInterested: true
      }
    case GET_TASK_LIST_SUCCESS:
      return {
        ...state,
        taskList: action.payload.task
      }
    case POST_TASK_LIST_SUCCESS:
      return {
        ...state,
      }
    case POST_TIMELINE_LIST_SUCCESS:
      return {
        ...state,

      }
    case GET_TIMELINE_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case AMI_INTERESTED_SUCCESS:
      return {
        ...state,
        iamInterested: action.payload
      }
    case AMI_INTERESTED_FAILURE:
      return {
        ...state,
        iamInterested: false
      }
    case ALL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case INFORMATION_DEVELOPER_SUCCESS:
      return {
        ...state,
        ...action.payload
      }

    case INFORMATION_DEVELOPER_PENDING:
      return {
        ...state,
        ...action.payload
      }

    case INFORMATION_DEVELOPER_FAILURE:
      return {
        ...state,
        ...action.payload
      }
    case INFORMATION_DEVELOPER_GEN_AI_SUCCESS:
      return {
        ...state,
        ...action.payload
      }

    case INFORMATION_DEVELOPER_GEN_AI_PENDING:
      return {
        ...state,
        ...action.payload
      }

    case INFORMATION_DEVELOPER_GEN_AI_FAILURE:
      return {
        ...state,
        ...action.payload
      }
    case INFORMATION_DEVELOPER_UNAUTHORISED:
      return {
        ...state,
        ...action.payload
      }
    case HIGHLIGHT_I_AM_INTERESTED:
      return {
        ...state,
        ...action.payload
      }
    case HIGHLIGHT_REMOVE_I_AM_INTERESTED:
      return {
        ...state,
        ...action.payload
      }
    case HIGHLIGHT_MORE_QUESTION:
      return {
        ...state,
        ...action.payload
      }
    case HIGHLIGHT_REMOVE_MORE_QUESTION:
      return {
        ...state,
        ...action.payload
      }
    case HIGHLIGHT_DOCUMENTS:
      return {
        ...state,
        ...action.payload
      }
    case HIGHLIGHT_REMOVE_DOCUMENTS:
      return {
        ...state,
        ...action.payload
      }
    case TASK_ANIMATION_START:
      return {
        ...state,
        ...action.payload
      }
    case TASK_ANIMATION_END:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_ALL_PROJECT_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
};
