import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import HomePage from "../pages/home/HomePage";
import LoginPage from "../pages/login/LoginPage";
import SignUpPage from "../pages/signup/SignUpPage";
import LandingPage from "../pages/landingPage/LandingPage";
import Onboarding from "../pages/additionalDetails/AdditionalDetails";
import Letschat from "../pages/letschat/Letschat";
import ScheduleAnAppointment from "../pages/scheduleAnAppointment/ScheduleAnAppointment";
import SigninConfirm from "../pages/login/SigninConfirm";
import Dashboard from "../pages/dashboard/Dashboard";
import Projects from "../pages/projects/Projects";
import Admin from "../pages/admin/Admin";
import ProjectsDetails from "../pages/ProjectsDetails/ProjectsDetails";
import Documentation from "../pages/projects/Documentation";

function ProtectedRoute({ children }) {
  const userdata = useSelector((state) => state.auth);
  const location = useLocation();

  if (!userdata.loggedIn) {
    return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} replace />;
  }

  return children;
}


export default function BasicRoutes() {
  const userdata = useSelector((state) => state.auth);
  return (
    <Routes>
   
      <Route path="/login" element={userdata.loggedIn ? <Navigate to= "/admin" /> : <LoginPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/lets-chat" element={<Letschat />} />
      <Route path="/schedule-an-appointment" element={<ScheduleAnAppointment />} />
      <Route path="/home" element={userdata.loggedIn ? <HomePage /> : <Navigate to="/" /> }  />
      <Route path="/dashboard" element = {<Dashboard />} />
      <Route path="/getstarted" element={<LandingPage />} />
      <Route path="/signin-confirm" element={ <SigninConfirm />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/project/:uuid" element={<ProjectsDetails />} />
      <Route path="/project/documentation/:uuid" element={<ProtectedRoute><Documentation /></ProtectedRoute>} />
      <Route
          path="/"
          element={
            userdata.loggedIn ? <Navigate to= "/admin" /> : <LoginPage />
          }
        />
    </Routes>
  )
}
