import React, { ReactElement, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Button,
  Backdrop,
  IconButton,
  CircularProgress
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import CloseIcon from "@mui/icons-material/Close";
import ProjectCreateHeader from "./ProjectCreateHeader";
import CompanyDetails from "./CompanyDetails";
import ProjectDetails from "./ProjectDetails";
import { useSelector, useDispatch } from "react-redux";
import * as projectActions from "../../../../store/project";

function CreateProjectDetails(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [companyname, setCompanyname] = useState("");
  const [foundedyear, setFoundedyear] = useState("");
  const [website, setWebsite] = useState("");
  const [industry, setIndustry] = useState("");
  const [industryMulti, setIndustryMulti] = useState([]);
  const [url, setUrl] = useState("");
  const [revenue, setRevenue] = useState("");
  const [noOfEmployes, setNoOfEmployes] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [others, setOthers] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [errorYear, setErrorYear] = useState(false);
  const [errorEmployee, setErrorEmployee] = useState(false);
  const [errorIndustry, setErrorIndustry] = useState(false);
  const [error, setError] = useState({
    companyName: false,
    foundedyear: false,
    noOfEmployes: false,
  });
  const [ownername, setOwnername] = useState("");
  const [ownerdesignation, setOwnerdesignation] = useState("");
  const [email, setEmail] = useState("");
  const [errorOwner, setErrorOwner] = useState(false);
  const [errorDesignation, setErrorDesignation] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const [location, setLocation] = useState("");
  const [projectStage, setProjectStage] = useState("");
  const [projectDetails, setProjectDetails] = useState("");

  const [errorLocation, setErrorLocation] = useState(false);
  const [errorStage, setErrorStage] = useState(false);
  const [financingAmount, setFinancingAmount] = useState("");
  const [paybackPeriod, setPaybackPeriod] = useState("");
  const [projectName, setProjectName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [OwnerPhoto, setOwnerPhoto] = useState("");
  const [risk, setRisk] = useState("");
  const [readiness, setReadiness] = useState("");

  const handleSubmit = async (companyData) => {

    setIsLoading(true);
    const data = {
      developerEmail: email,
      onboarding: {
        data: {
          GetStarted: "Capital Raising",
          CompanyName: companyname,
          CollateralOrSecurityAvailable: true,
          Email: email,
          TnC: true,
          CompanyName: companyname,
          YearFound: parseInt(foundedyear),
          Website: website,
          Url: "",
          logo: companyLogo,
          Owner: ownername,
          OwnerPhoto: OwnerPhoto,
          OwnerDesignation: ownerdesignation,
          RefinancingAndRevolvinBridgeLoans: true,

          RenewableEnergy: industryMulti.includes("Renewable Energy"),
          EnergyEfficiency: industryMulti.includes("Energy Efficiency"),
          SustainableTransportation: industryMulti.includes(
            "Sustainable Transportation"
          ),
          WasteManagement: industryMulti.includes("Waste Management"),
          WaterConservation: industryMulti.includes("Water Conservation"),
          BiomassBiofuel: industryMulti.includes("Biomass Biofuel"),
          Battery: industryMulti.includes("Battery"),
          AnnualRevenue: revenue,
          NumberOfEmployee: noOfEmployes,
          IndustryOther: others ? others : "",
        }
      },
      projectData: {
        data: {
          ProjectSummary: {
            ProjectSummary: ""
          },
          BasicData: {
            ProjectName: projectName,
            ProjectLocation: location,
            ProjectStage: projectStage,
            ProjectRisk: risk,
            ProjectReadiness: readiness
          },
          ProjectQuestion: {
            q1: financingAmount,
            payback: paybackPeriod
          }
        }
      },
      organisation:

      {
        name: companyname,
        tagline: "",
        logo: companyLogo,
        owner: ownername,
        ownerPhoto: OwnerPhoto,
        ownerDesignation: ownerdesignation,
        aboutUs: ""
      }
    }
 
    const onboarding = await dispatch(projectActions.postOnboardDeveloper(data));
    const authdata = await dispatch(projectActions.closeBackdrop());
    setIsLoading(false);
    const raw = ""
    const company = await dispatch(projectActions.fetchAllProject(raw));
 

  };
  const project = useSelector((state) => state.project);

  const handleOpen = () => {
    setOpen(true);
  };
  const saveToSummary = async () => { };

  const nextToSummary = async () => {
    const authdata = await dispatch(projectActions.saveSummary({}));
  };
  return (
    <>
      <Box>
        <CssBaseline />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
          open={project?.openBackdrop}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              height: "calc(100vh)",
              width: "80%",
              padding: "24px 64px",
              overflowY: "auto",
            }}
          >
            <ProjectCreateHeader />
            <CompanyDetails
              companyname={companyname}
              setCompanyname={setCompanyname}
              foundedyear={foundedyear}
              setFoundedyear={setFoundedyear}
              website={website}
              setWebsite={setWebsite}
              industry={industry}
              setIndustry={setIndustry}
              industryMulti={industryMulti}
              setIndustryMulti={setIndustryMulti}
              url={url}
              setUrl={setUrl}
              revenue={revenue}
              setRevenue={setRevenue}
              noOfEmployes={noOfEmployes}
              setNoOfEmployes={setNoOfEmployes}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              fileName={fileName}
              setFileName={setFileName}
              others={others}
              setOthers={setOthers}
              errorName={errorName}
              setErrorName={setErrorName}
              errorYear={errorYear}
              setErrorYear={setErrorYear}
              errorEmployee={errorEmployee}
              setErrorEmployee={setErrorEmployee}
              errorIndustry={errorIndustry}
              setErrorIndustry={setErrorIndustry}
              error={error}
              setError={setError}
              ownername={ownername}
              setOwnername={setOwnername}
              ownerdesignation={ownerdesignation}
              setOwnerdesignation={setOwnerdesignation}
              email={email}
              setEmail={setEmail}
              errorOwner={errorOwner}
              setErrorOwner={setErrorOwner}
              errorDesignation={errorDesignation}
              setErrorDesignation={setErrorDesignation}
              errorEmail={errorEmail}
              setErrorEmail={setErrorEmail}
              companyLogo={companyLogo}
              setCompanyLogo={setCompanyLogo}
              OwnerPhoto={OwnerPhoto}
              setOwnerPhoto={setOwnerPhoto}
              onSubmit={handleSubmit}
            />
            <ProjectDetails
              projectName={projectName}
              setProjectName={setProjectName}
              location={location}
              setLocation={setLocation}
              projectStage={projectStage}
              setProjectStage={setProjectStage}
              financingAmount={financingAmount}
              setFinancingAmount={setFinancingAmount}
              errorName={errorName}
              setErrorName={setErrorName}
              errorLocation={errorLocation}
              setErrorLocation={setErrorLocation}
              errorStage={errorStage}
              setErrorStage={setErrorStage}
              setPaybackPeriod={setPaybackPeriod}
              paybackPeriod={paybackPeriod}
              risk={risk}
              setRisk={setRisk}
              readiness={readiness}
              setReadiness={setReadiness}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                pt: 2,
              }}
            >

{isLoading ? (
                                    <Button
                                        disabled
                                        fullWidth
                                        variant="contained"
                                        sx={{
                                            mt: 0,
                                            mb: 2,
                                            height: "52px",
                                            textTransform: "none",
                                            fontSize: "18px",
                                        }}
                                    >
                                        <CircularProgress size={24} color="inherit" />
                                    </Button>

                                ) : (
                                  <Button
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                  sx={{
                                    textTransform: "capitalize",
                                    width: "100%",
                                    fontSize: "18px",
                                  }}
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  Submit
                                </Button>
                                )}

            </Box>
          </Box>
        </Backdrop>
      </Box>
    </>
  );
}

export default CreateProjectDetails;
