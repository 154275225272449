import * as React from "react";
import { Box, Typography, Button } from "@mui/material";
import upload from "../../../../assets/uploadIcon.svg";
import eye from "../../../../assets/eye.svg";
import refresh from "../../../../assets/refreshIcon.svg";
import deleteIcon from "../../../../assets/deleteIcon.svg";
import editIcon from "../../../../assets/edit_icon.svg";
import UploadBackdrop from "../UploadPopup/UploadPopup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import * as projectActions from "../../../../store/project";
import EditDocPopup from "../EditDocPopup/EditDocPopup";
import DeleteDocPopup from "../DeleteDocPopup/DeleteDocPopup";
import moment from "moment";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "ALP Technologies",
    "Feb 24th, 2024, 08.53 AM",
    "Pdf",
    "Finance",
    "Term-sheet created",
    "action"
  ),
];

function Documents() {
  const dispatch = useDispatch();
  const documents = useSelector((state) => state?.project?.documents);
  const uploadDoc = async () => {
    const openUpload = await dispatch(projectActions?.openUploadDocBackdrop());
  };

  const editDoc = async (doc) => {
    const deletedoc = await dispatch(projectActions?.openEditDocBackdrop(doc));
  };


  const deleteDoc = async (doc) => {
    const deletedoc = await dispatch(projectActions?.openDeleteDocBackdrop(doc));
  };

  

  return (
    <>
      <UploadBackdrop />
      <EditDocPopup />
      <DeleteDocPopup />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography>Documents</Typography>
        <Button
          sx={{ height: "38px" }}
          variant="outlined"
          startIcon={<img src={upload} />}
          onClick={uploadDoc}
        >
          Upload Documents
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead sx={{ backgroundColor: "#F3F4F9" }}>
            <TableRow>
              <TableCell>Document name</TableCell>
              <TableCell align="right">Uploaded on</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Category</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents && documents?.map((row) => (
              <TableRow key={row?.name}>
                <TableCell component="th" scope="row">
                  {row?.title}
                </TableCell>
                <TableCell align="right">{moment(row?.timestamp).format('lll')}</TableCell>
                <TableCell align="right">{row?.type}</TableCell>
                <TableCell align="right">{row?.category}</TableCell>
                <TableCell align="right">
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", alignItems:"center",gap: 2 }}
                  >
                    <a
                      href={row?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <IconButton aria-label="see">
                        <img src={eye} alt="View Project" />
                      </IconButton>
                    </a>
                    <IconButton aria-label="edit">
                      <img src={editIcon} alt="edit" onClick={() => editDoc(row)} />
                    </IconButton>
                    <IconButton aria-label="deleteIcon" onClick={() => deleteDoc(row)}>
                      <img src={deleteIcon} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Documents;
