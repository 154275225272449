import React, { ReactElement, useState, useEffect } from "react";
import {
    Box,
    Avatar,
    Typography,
    Button,
    Backdrop,
    IconButton,
    TextField,
    MenuItem
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import createproject from "../../../../assets/createproject.svg";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import * as projectActions from "../../../../store/project";
import Close from "../../../../assets/close.svg";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import { useNavigate, useLocation } from "react-router-dom";
import closed from "../../../../assets/close.svg";
import axios from "axios";
import { client } from "../../../../services/client";
import config from "../../../../config/config";

const optionsDocumentsType = [
    { name: "Incorporation", value: "Incorporation" },
    { name: "Project related files", value: "Project related files" },
    { name: "Financial documents", value: "Financial documents" },
    { name: "Permits and License", value: "Permits and License" },
    { name: "Bio and Organisation chart", value: "Bio and Organisation chart" },
    { name: "Collateral", value: "Collateral" },
    { name: "Others", value: "Others" },
];


function InviteUserPopup(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(true);
    const [link, setLink] = React.useState("");
    const [documentMulti, setDocumentMulti] = React.useState([]);
    const project = useSelector((state) => state.project);
    const handleClose = async () => {
        const authdata = await dispatch(projectActions.closeRequestDocumentsBackdrop());
    };
    const handleOpen = () => {
        setOpen(true);
    };



    const navigate = useNavigate();
    let { uuid } = useParams();
    const [name, setName] = useState("");
    const [phoneno, setPhoneno] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [errorDocument, setErrorDocument] = useState(false);
    const [description, setDescription] = useState("");
    const user = useSelector((state) => state?.auth?.user);
    const logedIn = useSelector((state) => state?.auth?.loggedIn);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const raw = {
            requestType: "request document",
            subject: documentMulti.join(),
            description: description,
            uuid: uuid
        };

        const task = {
            priority:"High Priority",
            subject: "Request Documents",
            description: 'Requested documents are ' + documentMulti.join(), 
            timestamp: Date.now(),
            userdata: {
                displayName: user?.displayName,
                photoURL : user?.photoURL
            },
        }

        if (documentMulti?.length > 0) {
            const authdata = await dispatch(projectActions.requestDocuments(raw, task));
            // const addTask = await dispatch(projectActions.postTaskList(uuid, task))
            const closebackdrop = await dispatch(projectActions.closeContactUsBackdrop());
            setDescription("");
            setDocumentMulti([])
        }
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    }
    const handleDocumentChange = (event) => {
        // setIndustry(event.target.value);
        setErrorDocument(false);
        const {
            target: { value },
        } = event;
        setDocumentMulti(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };


    return (
        <>
            <Box>
                <CssBaseline />
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        display: "flex",
                        justifyContent: "center",
                    }}
                    open={project?.requestDocumentsBackdrop}
                >
                    <Box
                        sx={{
                            backgroundColor: "#fff",
                            height: "auto",
                            maxWidth: "500px",
                            width: "500px",
                            margin: "32px",
                            padding: "24px 24px",
                            overflowY: "auto",
                            borderRadius: "8px"
                        }}
                    >

                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant="h6" sx={{ color: "#12190F", fontWeight: "700", letterSpacing: "0.9px" }}>
                                    Request Documents
                                </Typography>
                                <IconButton aria-label="delete" size="small" onClick={handleClose}>
                                    <Box>
                                        <img src={Close} height={"24px"} />
                                    </Box>
                                </IconButton>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                height: "calc ( 100vh - 120px)",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CssBaseline />
                            <Box
                                sx={{
                                    display: "flex",
                                    width: { xs: "100%", sm: "500px" },
                                    p: 2,
                                    flexDirection: "column",
                                }}
                            >

                                <Box sx={{ pt: 3 }}>
                                    <label for="industry">
                                        Document Type<span style={{ color: "red" }}>*</span>
                                    </label>

                                    <TextField
                                        id="outlined-select-gender"
                                        select
                                        label={
                                            !documentMulti || documentMulti?.length == 0
                                                ? "Select documents type"
                                                : ""
                                        }
                                        InputLabelProps={{ shrink: false }}
                                        SelectProps={{
                                            multiple: true,
                                            value: documentMulti,
                                            onChange: handleDocumentChange,
                                            renderValue: (selected) => selected.join(", "),
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                        size="medium"
                                        multiple
                                        value={documentMulti}
                                        onChange={handleDocumentChange}
                                        sx={{
                                            width: "100%",
                                            color: "#606060",
                                            marginTop: "8px",


                                            "& .MuiInputLabel-root": {
                                                color: "#9BA5B7 !important",
                                            },
                                        }}
                                    >
                                        {optionsDocumentsType.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                <Checkbox
                                                    checked={documentMulti.indexOf(option.value) > -1}
                                                />
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    {errorDocument && (
                                        <span style={{ color: "#F55B64", fontSize: "14px" }}>
                                            Select an option
                                        </span>
                                    )}
                                </Box>

                                <Box sx={{ padding: "16px 0px", width: "100%", display: "flex", flexDirection: "column", gap: 1 }}>
                                    <label for="companyname">
                                        Description (optional)
                                    </label>

                                    <textarea
                                        style={{ width: "100%", height: "160px", borderColor: "#BCBCBC", borderRadius: "8px", padding: "8px", }}
                                        label="Description (Optional)"
                                        id="outlined-multiline-flexible"
                                        multiline
                                        maxRows={8}
                                        minRows={4}
                                        value={description}
                                        onChange={handleDescriptionChange}
                                    />

                                </Box>

                                <Button
                                    onClick={handleSubmit}
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        mt: 0,
                                        mb: 2,
                                        height: "52px",
                                        textTransform: "none",
                                        fontSize: "18px",
                                    }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                </Backdrop>
            </Box>
        </>
    );
}

export default InviteUserPopup;
