import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  set,
  ref,
  getDatabase,
  onValue,
  remove,
  update,
} from "firebase/database";
import * as authActions from "../../../../store/auth";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import CssBaseline from "@mui/material/CssBaseline";
import questions from "../../../../assets/questions.png";
import upload from "../../../../assets/upload.svg";
import Checkbox from "@mui/material/Checkbox";
import { countries } from "../../../../utils/country";
import axios from "axios";
import config from "../../../../config/config";

const genders = [
  {
    value: "M",
    label: "Male",
  },
  {
    value: "F",
    label: "Female",
  },
  {
    value: "O",
    label: "Other",
  },
];

const optionsNoOfEmployees = [
  { name: "1 - 10", value: "1-10" },
  { name: "11 - 20", value: "11-20" },
  { name: "20 - 50", value: "21-50" },
  { name: "51 - 100", value: "51-100" },
  { name: "100 - 1000", value: "100-1000" },
  { name: "1000+", value: "1000+" },
];

const optionsIndustry = [
  { name: "Renewable Energy", value: "Renewable Energy" },
  { name: "Energy Efficiency", value: "Energy Efficiency" },
  { name: "Sustainable Transportation", value: "Sustainable Transportation" },
  { name: "Waste Management", value: "Waste Management" },
  { name: "Water Conservation", value: "Water Conservation" },
  { name: "Biomass Biofuel", value: "Biomass Biofuel" },
  { name: "Battery", value: "Battery" },
  { name: "Others", value: "Others" },
];

const optionsRevenue = [
  { name: "Less than $250k", value: "$250k" },
  { name: "$250k - $1M", value: "$250k-$1M" },
  { name: "$1M - $5M", value: "$1M-$5M" },
  { name: "More than $5M", value: ">$5M" },
];

const optionsStage = [
  { name: "Planning", value: "planning" },
  { name: "Development", value: "development" },
  { name: "Construction", value: "construction" },
  { name: "Operation", value: "operation" },
];

const optionsRisk = [
  { name: "Low", value: "Low" },
  { name: "Medium", value: "Medium" },
  { name: "High", value: "High" },
];



const optionsPayback = [
  { name: "< 2yrs", value: "< 2yrs" },
  { name: "2-5 yrs", value: "2-5 yrs" },
  { name: "5-10 yrs", value: "5-10 yrs" },
  { name: "10+ yrs", value: "10+ yrs" },
];

export default function ProjectDetails(props) {
  const {
    projectName,
    setProjectName,
    location,
    setLocation,
    projectStage,
    setProjectStage,
    financingAmount,
    setFinancingAmount,
    timeframe,
    setTimeframe,
    paybackPeriod,
    setPaybackPeriod,
    errorName,
    setErrorName,
    errorLocation,
    setErrorLocation,
    errorStage,
    setErrorStage,
    risk,
    setRisk,
    readiness,
    setReadiness

  } = props;


  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const companydata = useSelector((state) => state?.auth?.companyData);
  const getstarted = useSelector((state) => state?.auth?.GetStarted);
  const finacedata = useSelector((state) => state?.auth?.finaceData);
  const projectdata = useSelector((state) => state?.auth?.projectData);
  const companyData = useSelector((state) => state?.auth?.companyData);

  const user = useSelector((state) => state?.auth?.user);

  const handleProjectNameChange = (event) => {
    setErrorName(false);
    setProjectName(event.target.value);
  };



  const handleFinancingAmountChange = (event) => {
    setFinancingAmount(event.target.value);
  };




  useEffect(() => {

  }, [companydata]);

  const handleLocationChange = (event, country) => {
    setLocation(country);
  };


  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  const handlePaybackChange = (event) => {
    setPaybackPeriod(event.target.value);
  };

  const handleProjectStageChange = (event) => {
    setProjectStage(event.target.value);
  };

  const handlePaybackPeriodChange = (event) => {
    setPaybackPeriod(event.target.value);
  };
  const handleProjectRiskChange = (event) => {
    setRisk(event.target.value);
  };

  const handleProjectReadinessChange = (event) => {
    setReadiness(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",

          width: "100%",
        }}
      >
        <CssBaseline />
        <Container
          sx={{
            display: "flex",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#ffffff",
            backgroundPosition: "right 0% bottom 45%",
            paddingLeft: "0px!important",
            width: "100%"
          }}
        >
          <Box sx={{ pt: 2, width: "100%" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
              <Typography
                component="h4"
                variant="h4"
                sx={{
                  fontWeight: "600",
                  letterSpacing: "1px",
                  fontSize: "18px",
                  color: "#12190F",
                }}
              >
                Project Information
              </Typography>
            </Box>

            <Box sx={{ mt: 1, maxWidth: "100%" }}>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2, width: "100%" }}>
                <Box
                  sx={{
                    pt: 2,
                    display: "flex",
                    flexDirection: "column",
                    width: { md: "33%", sx: "100%" }
                  }}
                >
                  <label for="projectdescription">Project Name</label>

                  <input
                    type="text"
                    id="projectname"
                    name="projectname"
                    placeholder="Enter project name"
                    value={projectName}
                    onChange={handleProjectNameChange}
                  />

                  {errorName && (
                    <span style={{ color: "#F55B64", fontSize: "14px" }}>
                      Input fields has to be filled before clicking next
                    </span>
                  )}
                </Box>

                <Box sx={{ pt: 2, width: { md: "33%", sx: "100%" } }}>
                  <label for="location">Project Location</label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={countries}
                    sx={{ width: "100%", border: "0px", marginTop: "-8px" }}
                    value={location}
                    onChange={handleLocationChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: false }}
                        SelectProps={{}}
                        margin="normal"
                        sx={{
                          border: "0px !important",
                          "& .MuiOutlinedInput-input": {
                            border: "0px !important",
                            // Class for the border around the input field
                          },
                        }}
                        size="medium"
                        label="" //{(!location || location== "")?"Search and select your location" :  "" }
                      />
                    )}
                  />

                  {errorLocation && (
                    <span style={{ color: "#F55B64", fontSize: "14px" }}>
                      Select Location before clicking next
                    </span>
                  )}
                </Box>

                <Box sx={{ pt: 2, width: { md: "33%", sx: "100%" } }}>
                  <label for="projectstage">Project Stage</label>

                  <TextField
                    id="outlined-select-gender"
                    select
                    label={
                      !projectStage || projectStage == ""
                        ? "Select project stage"
                        : ""
                    }
                    InputLabelProps={{ shrink: false }}
                    SelectProps={{}}
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={projectStage}
                    onChange={handleProjectStageChange}
                    sx={{
                      width: "100%",
                      color: "#606060",
                      marginTop: "8px",
                      "& .MuiInputLabel-root": {
                        color: "#9BA5B7 !important",
                      },
                    }}
                  >
                    {optionsStage.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  {errorStage && (
                    <span style={{ color: "#F55B64", fontSize: "14px" }}>
                      Select project stage before clicking next
                    </span>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2, width: "100%" }}>
                <Box sx={{ pt: 2, width: { md: "33%", sx: "100%" } }}>
                  <label for="financingAmount">
                    Financing Amount Sought (In USD)
                  </label>
                  <input
                    type="text"
                    id="financingAmount"
                    name="financingAmount"
                    placeholder=""
                    value={financingAmount}
                    onChange={handleFinancingAmountChange}
                  />
                </Box>
                <Box sx={{ pt: 2, width: { md: "33%", sx: "100%" } }}>
                  <label for="paybackPeriod">Project Payback Period</label>

                  <TextField
                    id="outlined-select-gender"
                    select
                    label={
                      !paybackPeriod || paybackPeriod == ""
                        ? "Select Payback Perios"
                        : ""
                    }
                    InputLabelProps={{ shrink: false }}
                    SelectProps={{}}
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={paybackPeriod}
                    onChange={handlePaybackChange}
                    sx={{
                      width: "100%",
                      color: "#606060",
                      marginTop: "8px",
                      "& .MuiInputLabel-root": {
                        color: "#9BA5B7 !important",
                      },
                    }}
                  >
                    {optionsPayback.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  {errorStage && (
                    <span style={{ color: "#F55B64", fontSize: "14px" }}>
                      Select project stage before clicking next
                    </span>
                  )}
                </Box>
                <Box sx={{ pt: 2, width: { md: "33%", sx: "100%" } }}>
                  <label htmlFor="projectRisk">Project Risk</label>
                  <TextField
                    id="outlined-select-risk"
                    select
                    label={!risk || risk === "" ? "Select Project Risk" : ""}
                    InputLabelProps={{ shrink: false }}
                    SelectProps={{}}
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    value={risk}
                    onChange={handleProjectRiskChange}
                    sx={{
                      width: "100%",
                      color: "#606060",
                      marginTop: "8px",
                      "& .MuiInputLabel-root": {
                        color: "#9BA5B7 !important",
                      },
                    }}
                  >
                    {optionsRisk.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
   
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2, width: "100%" }}>
                  <Box sx={{ pt: 2, width: { md: "33%", sx: "100%" } }}>
                    <label htmlFor="projectReadiness">Project Readiness</label>
                    <input
                      type="text"
                      id="projectReadiness"
                      name="projectReadiness"
                      placeholder="Enter project readiness"
                      value={readiness}
                      onChange={handleProjectReadinessChange}
                    />
                  </Box>
                </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

// <Box sx={{ pt: 3 }}>
// <label for="uploadfile">
//   Upload Any relevant Company presentation, If any
// </label>
// <Box
//   sx={{
//     width: "100%",
//     border: "dashed 2px #BCBCBC",
//     borderSpacing: "8px",
//     borderWidth: "1px",

//     mt: 2,
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//   }}
// >
//   <Box
//     sx={{
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "center",
//       alignItems: "center",
//       p: 4,
//       cursor: "pointer",
//     }}
//   >
//     <button
//       onClick={handleIconClick}
//       style={{
//         cursor: "pointer",
//         border: "0",
//         backgroundColor: "#fff",
//       }}
//     >
//       {/* You can replace the icon with any icon library or custom SVG */}
//       <img src={upload} alt="Upload" height="32px" />
//     </button>
//     <input
//       type="file"
//       multiple
//       ref={fileInputRef}
//       style={{ display: "none" }}
//       onChange={handleFileInputChange}
//     />
//     <Typography
//       variant="h6"
//       sx={{
//         fontSize: "16px",
//         lineHeight: "32px",
//         color: "#008080",
//         fontWeight: "600",
//       }}
//     >
//       Click to upload
//     </Typography>
//     <Typography
//       variant="h6"
//       sx={{
//         fontSize: "14px",
//         lineHeight: "24px",
//         color: "#808080",
//       }}
//     >
//       Supported format : PDF, PPT, Keynote, Jpeg, PNG
//     </Typography>
//   </Box>
// </Box>

// {url?.length > 0 && (
// <Box>
// <p>Selected files:</p>
// <ul>
// {url?.map((file, index) => (
// <li key={index}>{file?.fileName}</li>
// ))}
// </ul>
// </Box>
// )}
// </Box>
