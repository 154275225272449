import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Backdrop,
    IconButton,
    TextField,
    MenuItem,
    Checkbox
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector, useDispatch } from "react-redux";
import * as projectActions from "../../../../store/project";
import Close from "../../../../assets/close.svg";
import { useParams } from "react-router-dom";
import {optionsDocumentsType} from "../../../../utils/category";


function DeleteDocPopup() {
    const dispatch = useDispatch();
    let { uuid } = useParams();
    const [summary, setSummary] = useState("");
    const [documentMulti, setDocumentMulti] = useState([]);
    const [errorDocument, setErrorDocument] = useState(false);
    const project = useSelector((state) => state.project);
    const doc = useSelector((state) => state?.project?.doc);

    useEffect(() => {
      
        if (doc) {
            setSummary(doc?.title)
            setDocumentMulti([doc?.category])
        }
    }, [doc]);

    const handleClose = async () => {
        const authdata = await dispatch(projectActions.closeDeleteDocBackdrop());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...doc,
            title: summary,
            category: documentMulti[0],
            
        }
        if(doc){
        await dispatch(projectActions.deleteDocument(doc?.id));
        const docu = await dispatch(projectActions.allDocuments(uuid));

        await dispatch(projectActions.closeDeleteDocBackdrop());
        }
    }

    const handleSummaryChange = (e) => {
        setSummary(e.target.value)
    }


    const handleDocumentChange = (event) => {
        setErrorDocument(false);
        const {
            target: { value },
        } = event;
        setDocumentMulti(
            typeof value === "string" ? value.split(",") : value
        );
    };

    return (
        <Box>
            <CssBaseline />
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    display: "flex",
                    justifyContent: "center",
                }}
                open={project?.deleteBackdrop}
            >
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        height: "auto",
                        maxWidth: "500px",
                        width: "500px",
                        margin: "32px",
                        padding: "24px 24px",
                        overflowY: "auto",
                        borderRadius: "8px"
                    }}
                >
                    <Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="h6" sx={{ color: "#12190F", fontWeight: "700", letterSpacing: "0.9px" }}>
                                Delete Document
                            </Typography>
                            <IconButton aria-label="delete" size="small" onClick={handleClose}>
                                <Box>
                                    <img src={Close} height={"24px"} alt="Close" />
                                </Box>
                            </IconButton>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            height: "calc ( 100vh - 120px)",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CssBaseline />
                        <Box
                            sx={{
                                display: "flex",
                                width: { xs: "100%", sm: "500px" },
                                p: 2,
                                flexDirection: "column",
                            }}
                        >
                            <Box sx={{ display: "flex", width: '100%', flexDirection: "column", gap: 1, pt: 1, pb: 1 }}>
                            <label htmlFor="industry">
                                    Are you sure to delete  this document
                                </label>
                                <label htmlFor="industry">
                                {doc?.title}
                                </label>
                               
                 
                            </Box>


                      

<Box sx={{display:"flex", gap : 2,  mt: 2,}}>

                            <Button
                                onClick={handleSubmit}
                                fullWidth
                                variant="contained"
                                sx={{
                                   
                                    mb: 2,
                                    height: "52px",
                                    textTransform: "none",
                                    fontSize: "18px",
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={handleClose}
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 0,
                                    mb: 2,
                                    height: "52px",
                                    textTransform: "none",
                                    fontSize: "18px",
                                }}
                            >
                                No
                            </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Backdrop>
        </Box>
    );
}

export default DeleteDocPopup;